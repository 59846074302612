export default [
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/products/Products.vue'),
    meta: {
      pageTitle: 'Каталог товаров',
      resource: 'products',
      breadcrumb: [
        {
          text: 'Все товары',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product/create',
    name: 'product-create',
    component: () => import('@/views/products/ProductCreate.vue'),
    meta: {
      pageTitle: 'Создание товара',
      contentClass: 'ecommerce-application',
      // action: 'read',
      resource: 'products',
    },
  },
  {
    path: '/product/:id',
    name: 'product-details',
    component: () => import('@/views/remains/ProductDetails.vue'),
    meta: {
      pageTitle: 'Детали товара',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/product/:id/details',
    name: 'dictionary-product-details',
    component: () => import('@/views/products/ProductDetails.vue'),
    meta: {
      pageTitle: 'Детали товара',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/product/:id/edit',
    name: 'product-edit',
    props: true,
    component: () => import('@/views/products/ProductUpdate.vue'),
    meta: {
      pageTitle: 'Детали товара',
      contentClass: 'ecommerce-application',
      // action: 'read',
      resource: 'products',
    },
  },
];
