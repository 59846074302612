import axios from 'axios';

export const get = (url, params = null) => {
  const requestObject = {
    url: `${process.env.VUE_APP_API_ENDPOINT}/${url}`,
    params,
    method: 'get',
    headers: {
      ...(localStorage.getItem('accessToken') && { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }),
      ...(params && params.headers),
    },
  };
  return axios(requestObject).catch((e) => {
    if (e.response.status === 401) {
      localStorage.removeItem('userData');
      window.location.href = '/login';
    } else {
      throw e;
    }
  });
};

export const download = (url, fileName = null, params = null) => {
  const requestObject = {
    url: `${process.env.VUE_APP_API_ENDPOINT}/${url}`,
    params,
    method: 'get',
    responseType: 'blob',
    headers: {
      ...(localStorage.getItem('accessToken') && { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }),
      ...(params && params.headers),
    },
  };
  return axios(requestObject).then((response) => {
    const fakeUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fakeUrl;
    link.setAttribute('download', fileName || 'file');
    document.body.appendChild(link);
    link.click();
  });
};

export const post = (url, data) => {
  const requestObject = {
    url: `${process.env.VUE_APP_API_ENDPOINT}/${url}`,
    data,
    method: 'post',
    headers: {
      ...(localStorage.getItem('accessToken') && { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios(requestObject);
};
