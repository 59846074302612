export default [
  // Automation
  {
    path: '/rozetka',
    props: {
      externalServiceId: 3,
      prefix: 'rozetka',
    },
    component: () => import('@/views/automation/ExternalService.vue'),
    children: [
      {
        path: 'configuration',
        name: 'rozetka-configuration',
        component: () => import('@/views/automation/tabs/ExternalServiceConfiguration.vue'),
        meta: {
          pageTitle: 'Rozetka',
        },
      },
      {
        path: 'categories/:id?',
        name: 'rozetka-categories',
        component: () => import('@/views/automation/tabs/ExternalServiceExternalCategory.vue'),
        meta: {
          pageTitle: 'Rozetka',
        },
      },
      {
        path: 'category/edit/:id',
        name: 'rozetka-category-update',
        component: () => import('@/views/automation/ExternalCategoryUpdate.vue'),
        meta: {
          pageTitle: 'Rozetka',
        },
      },
      {
        path: 'category/create',
        name: 'rozetka-category-create',
        component: () => import('@/views/automation/ExternalCategoryCreate.vue'),
        meta: {
          pageTitle: 'Rozetka',
        },
      },
      {
        path: 'brandstock-categories',
        name: 'rozetka-brandstock-categories',
        component: () => import('@/views/automation/tabs/ExternalServiceCategory.vue'),
        meta: {
          pageTitle: 'Rozetka',
        },
      },
      {
        path: 'prices',
        name: 'rozetka-prices',
        component: () => import('@/views/automation/tabs/ExternalServicePrices.vue'),
        meta: {
          pageTitle: 'Rozetka',
        },
      },
    ],
  },
  {
    path: '/kidstaff',
    props: {
      externalServiceId: 6,
      prefix: 'kidstaff',
    },
    component: () => import('@/views/automation/ExternalService.vue'),
    children: [
      {
        path: 'configuration',
        name: 'kidstaff-configuration',
        component: () => import('@/views/automation/tabs/ExternalServiceConfiguration.vue'),
        meta: {
          pageTitle: 'Kidstaff',
        },
      },
    ],
  },
];
