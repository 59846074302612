import { get, post } from '@/api/general';

export default {
    configuration: {
        info: (externalServiceId) => get(`external/${externalServiceId}/configuration`),
        priceManagement: (externalServiceId, body) => post(`external/${externalServiceId}/configuration/price/management`, body),
    },
    categories: {
        list: (externalServiceId, params) => get(`external/${externalServiceId}/categories`, params),
        select: (externalServiceId, params) => get(`external/${externalServiceId}/categories/select`, params),
        path: (categoryId) => get(`external/categories/${categoryId}/path`),
        get: (categoryId) => get(`external/categories/${categoryId}`),
        create: (externalServiceId, data) => post(`external/${externalServiceId}/categories/create`, data),
        update: (categoryId, data) => post(`external/categories/${categoryId}/update`, data),
        link: (externalCategoryId, data) => post(`external/categories/${externalCategoryId}/link`, data),
        unlink: (externalCategoryId, data) => post(`external/categories/${externalCategoryId}/unlink`, data),
        remove: (externalCategoryId) => post(`external/categories/${externalCategoryId}/remove`),
    },
    prices: {
        list: (externalServiceId, params) => get(`external/${externalServiceId}/prices`, params),
        update: (externalServiceId, data) => post(`external/${externalServiceId}/price/update`, data),
    },
};
