import orders from '@/api/orders';
import payments from '@/api/payments';
import products from '@/api/products';
import auth from '@/api/auth';
import cart from '@/api/cart';
import chat from '@/api/chat';
import external from '@/api/external';
import categories from '@/api/categories';
import account from '@/api/account';
import colors from '@/api/colors';
import countries from '@/api/countries';
import returns from '@/api/returns';
import options from '@/api/options';
import productSpecifications from '@/api/productSpecifications';
import conditions from '@/api/conditions';
import currencies from '@/api/currencies';
import userStock from '@/api/userStock';

export default {
  orders,
  auth,
  payments,
  products,
  cart,
  chat,
  external,
  categories,
  account,
  colors,
  countries,
  returns,
  options,
  productSpecifications,
  conditions,
  currencies,
  userStock,
};
