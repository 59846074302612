export default [
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/remains',
    name: 'remains',
    props: true,
    component: () => import('@/views/remains/Remains.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Каталог товаров',
      breadcrumb: [
        {
          text: 'Все товары',
          active: true,
        },
      ],
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/cart/Checkout.vue'),
    meta: {
      pageTitle: 'Оформление заказа',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/returns',
    name: 'returns',
    component: () => import('@/views/returns/Returns.vue'),
    meta: { resource: 'returns' },
  },
  {
    path: '/return/:id',
    name: 'return-details',
    component: () => import('@/views/returns/ReturnDetails.vue'),
    meta: { resource: 'returns' },
  },
  // Orders
  {
    path: '/orders',
    name: 'order-list',
    component: () => import('@/views/orders/OrderList.vue'),
  },
  {
    path: '/payment/list',
    name: 'payment-list',
    component: () => import('@/views/payments/Payments.vue'),
  },
  {
    path: '/order/:id',
    name: 'order-details',
    component: () => import('@/views/orders/OrderDetails.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
  },
  {
    path: '/account/:hash/:action(delete|confirm)',
    name: 'account-confirm',
    component: () => import('@/views/account/Confirm.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/stocks',
    name: 'stocks',
    props: true,
    component: () => import('@/views/stocks/Stocks.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Мой склад',
    },
  },
];
