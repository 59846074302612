import { get, post, download } from '@/api/general';

export default {
    list: (params) => get('products', params),
    remains: (params) => get('products/remains', params),
    exportRemains: (fileName = null) => download('products/remains/export/xls', fileName),
    getStockInfo: (productId) => get(`products/${productId}/stockInfo`),
    downloadImages: (productId, fileName = null) => download(`products/${productId}/images/zip`, fileName),
    uploadImages: (productId, body) => post(`products/${productId}/images/upload`, body),
    setMainImage: (productId, body) => post(`products/${productId}/images/setMain`, body),
    removeImage: (productId, body) => post(`products/${productId}/images/remove`, body),
    filters: (params) => get('products/filters', params),
    filterOptions: (params) => get('products/filter/options', params),
    get: (productId) => get(`products/${productId}`),
    getOffer: (productId) => get(`offer/${productId}`),
    create: (body) => post('products/create', body),
    dictionaries: (productId) => get(`products/${productId}/dictionaries`),
    update: (productId, body) => post(`products/${productId}/update`, body),
    specificationOptions: (productId) => get(`products/${productId}/specification/options`),
    searchAll: (params) => get('products/search/all', params),
};
