import { get, post } from '@/api/general';

export default {
    label: (productSpecificationId) => get(`productSpecification/${productSpecificationId}/print/label`),
    remove: (productSpecificationId) => post(`productSpecification/${productSpecificationId}/delete`),
    create: (productId, body) => post(`products/${productId}/specification/create`, body),
    update: (productSpecificationId, body) => post(`productSpecification/${productSpecificationId}/update`, body),
    get: (productSpecificationId) => get(`productSpecification/${productSpecificationId}`),
    stocks: (params) => get('productSpecification/stocks', params),
};
